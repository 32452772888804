
/*charts*/
.highcharts-container{
  display: flex;
  max-width: 100%;
}

.highcharts-container .highcharts-root,
.highcharts-container .highcharts-axis-labels>text{
  font-family: 'Roboto', sans-serif !important;
  color: #0F1324 !important;
  fill: #0F1324 !important;
}

.highcharts-container .highcharts-axis-labels>text{
  font-size: 12px !important;
}
