/** Office page*/
.PackageBgImg{
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  border-radius: 24px;
  width: 100%;
}
.singleNewsWrapperImg{
  object-fit: cover;
  width: 100% !important;
  height: 100%;
}

/* MainPage */
.ImgFull {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imageHeight100 {
  height: 100% !important;
}

.imageWidth100 {
  width: 100%;
}

.mainSectionRightItem {
  object-fit: cover;
  border-radius: 50%;
}

.mainSectionRightItems > span {
  overflow: visible !important;
}

.mainSectionRightItem-Left-60 {
  left: -60px !important;
}

.mainSectionRightItem-Left-120 {
  left: -120px !important;
}