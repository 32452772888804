.xlsxButton{
    flex: 0 0 48px;
    max-width: 48px;
    height: 48px;
    border: 1px solid rgba(15, 19, 36, 0.15);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    outline: transparent;
    position: relative;
}
.xlsxButtonSvg{
    width: 22px;
    height: 22px;
}
.xlsxButton p{
    display: none;
}
@media(max-width: 767px){
    .xlsxButton {
        flex: 0 0 147px;
        max-width: 147px;
        justify-content: flex-start;
        padding: 8px 12px;
        height: 40px;
    }
    .xlsxButton p{
        display: inline;
        padding-left: 8px;
        color: #0F1324;
    }
}
