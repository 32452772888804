
/*react datepickerWeb*/
.react-datepicker-wrapper {
  width: 100%;
}
html .react-datepicker__tab-loop{
  position: absolute;
  top: 0;
  z-index: 10;
}
html .react-datepicker__input-container input{
  width: 100%;
  font-size: 16px;
  color: #0F1324;
  font-family: inherit;
  background: transparent;
}
html .react-datepicker-wrapper{
  width: 100%;
}
html .react-datepicker{
  display: flex;
  font-size: 1em;
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  border-radius: 14px;
  border: 0;
  box-shadow: 0 0 50px 0 rgba(0,0,0,0.1);
}
html .calendarinline .react-datepicker{
  box-shadow: none;
  max-width: 256px;
}
html .react-datepicker__header{
  background: white;
  border: 0;
  padding: 12px 12px 0px 12px;
  border-radius: 14px 14px 0 0;
  display: flex;
  flex-wrap: wrap;
}
html .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll{
  padding-top: 8px;
  transform: translateX(calc(-100% + 15px));
  background: white;
}
html .react-datepicker__year-read-view{
  position: relative;
  padding-right: 15px;
  border: 0;
  visibility: visible!important;
}
html .react-datepicker__year-read-view--down-arrow{
  border-top-color: rgba(15,19,36,0.6);
  margin-left: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0,-50%);
  left: auto;
}
html .react-datepicker__year-read-view--selected-year{
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: block;
}
html .react-datepicker__day-names{
  flex: 0 0 100%;
  padding-top: 16px;
  text-transform: capitalize;
}

html .react-datepicker__day--in-range{
  background: #2c41350f;
  color: #0F1324;
}

html .react-datepicker__day--selected{
  background: #2c4135;
  border-radius: 8px;
  color: #FFFFFF;
}
html .react-datepicker__day--range-end.react-datepicker__day--in-range{
  background: #2c4135;
  border-radius: 0 8px 8px 0;
  color: #FFFFFF;
}
html .react-datepicker__day--range-start.react-datepicker__day--in-range{
  background: #2c4135;
  border-radius: 8px 0 0 8px;
  color: #FFFFFF;
}
html .react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown{
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  width: 200px;
  grid-template-columns: 1fr 1fr 1fr;
  height: 200px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: white;
  border: 0;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.06);
  align-items: center;
  transform: translateX(-33%);
}
html .react-datepicker__year-option:first-of-type{
  position: absolute;
  top: 0;
  width: 100%;
}
html .react-datepicker__year-option:last-of-type{
  position: absolute;
  bottom: 0;
  width: 100%;
}
html .react-datepicker__year-option:first-of-type:hover,
html .react-datepicker__year-option:last-of-type:hover{
  background-color: rgba(67,95,197,0.05);
}
html .react-datepicker__year-option:first-of-type a{
  border-bottom-color: rgba(15,19,36,0.6);
  opacity: 1!important;
  top: -5px;
  height: 16px;
}
html .react-datepicker__year-option:last-of-type a{
  border-top-color: rgba(15,19,36,0.6);
  opacity: 1!important;
  bottom: -5px;
  height: 16px;
}
html .react-datepicker__year-option:not(:first-of-type):not(:last-of-type){
  width: 56px;
  line-height: 32px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}
html .react-datepicker__year-option:not(:first-of-type):not(:last-of-type):hover{
  background: rgba(67,95,197,0.06);
}
html .react-datepicker__year-option.react-datepicker__year-option--selected_year{
  background: #2c4135!important;
  color: white;
}
html .react-datepicker__year-option.react-datepicker__year-option--selected_year .react-datepicker__year-option--selected{
  display: none;
}
html .react-datepicker__portal .react-datepicker__day-name,html .react-datepicker__portal .react-datepicker__day, html .react-datepicker__portal .react-datepicker__time-name{
  width: 32px;
  line-height: 32px;
}
@media (max-width: 400px), (max-height: 550px){
  html .react-datepicker__portal .react-datepicker__day-name, html .react-datepicker__portal .react-datepicker__day, html .react-datepicker__portal .react-datepicker__time-name{
    width: 32px;
    line-height: 32px;
  }
}
@media (max-width: 400px), (max-height: 550px){
  html .react-datepicker__portal .react-datepicker__day-name, html .react-datepicker__portal .react-datepicker__day, html .react-datepicker__portal .react-datepicker__time-name{
    width: 32px;
    line-height: 32px;
  }
}
@media (max-width:550px){
  html .react-datepicker__month-container{
    max-width: 260px;
  }
}
/*html .react-datepicker__navigation{*/
/*width: 20px;*/
/*height: 20px;*/
/*top: 20px;*/
/*-webkit-background-size: 6px, 10px;*/
/*background-size: 6px, 10px;*/
/*background-repeat: no-repeat;*/
/*background-position: center;*/
/*border: 0;*/
/*}*/
html .react-datepicker__navigation:hover{
  opacity: 0.6;
}
html .react-datepicker__navigation--previous,
html .react-datepicker__portal .react-datepicker__navigation--previous{
  width: 20px;
  height: 20px;
  top: 20px;
  -webkit-background-size: 6px, 10px;
  background-size: 6px, 10px;
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  right: 62px;
  left: auto;
  background-image: url("../../../public/src/img/arrow-left.svg");
}
html .react-datepicker__navigation--next,
html .react-datepicker__portal .react-datepicker__navigation--next{
  width: 20px;
  height: 20px;
  top: 20px;
  -webkit-background-size: 6px, 10px;
  background-size: 6px, 10px;
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  right: 12px;
  background-image: url("../../../public/src/img/arrow-right.svg");
}
html .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select{
  padding-top: 8px;
  transform: translateX(calc(-100% + 15px));
  background: white;
  padding-right: 15px;
  position: relative;
}
html .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select:after{
  content: "";
  border-top-color: #b3b3b3;
  margin-left: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0,-50%);
  left: auto;
  border-width: 0.45rem;
  border-bottom: none;
}
html .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select select{
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
html .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container{
  padding-right: 0px;
}
html .react-datepicker__month{
  margin: 0;
  padding: 0 10px 24px 10px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

html .react-datepicker__day-name, html .react-datepicker__day, .react-datepicker__time-name{
  /*width: 1.7em;*/
  /*line-height: 1.7em;*/
  text-align: center;
  width: 32px;
  line-height: 32px;
  margin:0;
  /*margin: 0.166em;*/
}
html .react-datepicker__day-name {
  font-weight: 500;
}
.react-datepicker__header--custom > div > div[class$="Header__yearData"] {
  /*height: 190px;*/
}
html react-datepicker__day{
  color: #0F1324;
}
html .react-datepicker__day.react-datepicker__day--outside-month{
  color: rgba(15,19,36,0.3);
}
html .react-datepicker__day.react-datepicker__day--outside-month.react-datepicker__day--in-range.react-datepicker__day--range-start,
html .react-datepicker__day.react-datepicker__day--outside-month.react-datepicker__day--in-range.react-datepicker__day--range-end {
  color: rgba(15,19,36,0.3);
  background: #2c4135ab;
}
html .react-datepicker__current-month,
html .react-datepicker__portal .react-datepicker__current-month{
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  padding: 8px 0 0 8px;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before{
  display:none!important;
}
html .single-datepicker .react-datepicker__day--keyboard-selected,
html .single-datepicker .react-datepicker__month-text--keyboard-selected,
html .single-datepicker .react-datepicker__quarter-text--keyboard-selected,
html .single-datepicker .react-datepicker__year-text--keyboard-selected{
  /* selected day*/
  background: #2c4135;
  border-radius: 8px;
  color: #fff;
}

.react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range.react-datepicker__day--today{
  /*start day in range datepickerWeb*/
  background: #2c4135;
  border-radius: 8px;
  color: #fff;
}
html .react-datepicker__day--keyboard-selected,
html .react-datepicker__month-text--keyboard-selected,
html .react-datepicker__quarter-text--keyboard-selected,
html .react-datepicker__year-text--keyboard-selected{
  background: #2c4135;
}
html .react-datepicker-range .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--selected):not(.react-datepicker__day--in-range){
  /* selected day*/
  background: #fff;
  color: #000;
}
html .react-datepicker__day:hover,
html .react-datepicker__month-text:hover,
html .react-datepicker__quarter-text:hover,
html .react-datepicker__year-text:hover{
  background: rgba(67,95,197,0.06);
  border-radius: 8px;
  color: #000;
}
html .single-datepicker .react-datepicker__day--keyboard-selected:hover,
html .single-datepicker .react-datepicker__month-text--keyboard-selected:hover,
html .single-datepicker .react-datepicker__quarter-text--keyboard-selected:hover,
html .single-datepicker .react-datepicker__year-text--keyboard-selected:hover{
  background: #2c4135;
  border-radius: 8px;
}
html .react-datepicker__time-container{
  border-left: 1px solid #f1f1f1;
}
html .react-datepicker-time__header{
  font-size: 16px;
  font-weight: 500;
}
html .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
  background: #2c4135;
}
html .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
  max-height: 236px;
}
/*html .react-datepicker__current-month, html .react-datepickerWeb-time__header, .react-datepickerWeb-year-header{*/
/*font-size: 0.944em;*/
/*}*/


#portal>div>.react-datepicker__tab-loop{
  position: static;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#portal>div>.react-datepicker__tab-loop:before,
#portal>div>.react-datepicker__tab-loop:after{
  content: '';
  display: block;
  flex-grow: 1;
}

#portal>div>.react-datepicker__tab-loop>.react-datepicker-popper{
  position: static !important;
  transform: none !important;
}

.react-datepicker__tab-loop>.react-datepicker-popper .react-datepicker{
  /*min-height: 288px;*/
}

#portal>div>.react-datepicker__tab-loop>.react-datepicker-popper .react-datepicker__triangle{
  display: none;
}

.react-datepicker__triangle{
  left: 100px !important;
  border-bottom-color: #fff !important;
}


.dpHeader .dpHeader__yearData{
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 60px;
  background-color: #fff;
  left: 12px;
  right: 12px;
  overflow: auto;
  height: 190px;
  scrollbar-width: thin;
}

.dpHeader .dpHeader__yearData::-webkit-scrollbar {
  height: 8px;
  background-color: #F5F5F5;
}

.dpHeader .dpHeader__yearData::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: #F5F5F5;
}

.dpHeader .dpHeader__yearData::-webkit-scrollbar-thumb {
  height: 8px;
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #D9DFE4;
}

.dpHeader .dpHeader__arrow {
  display: block;
  position: relative;
  width: 16px;
  height: 19px;
  padding: 5px;
  outline: none !important;
  background-color: transparent;
  border: none !important;
}

.dpHeader .dpHeader__arrow_right{
  margin-right: 0;
  margin-left: 21px;
  transform: scaleX(-1);
}

.dpHeader .dpHeader__arrow:before,
.dpHeader .dpHeader__arrow:after{
  content:'';
  position: absolute;
  top: 9px;
  left: 5px;
  right: 5px;
  width: calc(100% - 10px);
  height: 1px;
  background-color: #0F1324;
  transform-origin: center left;
  cursor: pointer;
  transition: background-color 0.5s;
  margin-right: 21px;
}

.dpHeader .dpHeader__arrow:before{
  transform: rotateZ(45deg);
}

.dpHeader .dpHeader__arrow:after{
  transform: rotateZ(-45deg);
}

.dpHeader .dpHeader__arrow:hover:before,
.dpHeader .dpHeader__arrow:hover:after{
  background-color: #2c4135;
}

.dpHorizontal.dateListScrollable {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.dpHorizontal.dateListScrollable::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}


.react-datepicker-popper{
  left: auto !important;
  right: 0;
}
.react-datepicker-popper[data-placement^="top"] {
  top: -28px !important;
}
.react-datepicker-popper[data-placement^="bottom"] {
  top: 4px !important;
}
.react-datepicker__tab-loop{
  right: 13px;
}
.react-datepicker__triangle{
  display: none !important;
}
html .react-datepicker__header{
  border-radius: 14px;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select){
  border-top-right-radius: 14px !important;
}
.range-datepicker input{
  font-size: 16px ;
}

html .react-datepicker__input-container {
  border: 0;
}

.pagemanagementTimeCalendar {
  .react-datepicker__day-names {
    div:not(:last-of-type) {
      @media(max-width: 768px) {
        margin-right: 20px;
      }
    }
  }
  .react-datepicker__week {
    div:not(:last-of-type) {
      @media(max-width: 768px) {
        margin-right: 20px;
      }
    }
  }
  .react-datepicker__header {
    max-width: 372px;
  }
  .react-datepicker__current-month {
    text-transform: capitalize;
  }
}

.pagemanagementTimeCalendar {
  .react-datepicker__month-container {
    max-width: none;
  }
  .react-datepicker__month {
    padding: 0 0 24px 0;
  }
}

.timeWrapper {
  .react-datepicker__input-container {
    input:disabled {
      opacity: unset;
    }
  }
}

.react-datepicker-range-custom-mobile {
  .react-datepicker__month-container {
    width: 100%;
    max-width: 100%;
  }
  .react-datepicker__day-names {
    div:not(:last-of-type) {
      margin-right: 20px;
    }
  }
  .react-datepicker__week {
    div:not(:last-of-type) {
      @media(max-width: 768px) {
        padding-right: 40px;
        padding-left: 12px;
      }
    }
  }
}