

/* phone widget */
html .react-tel-input{
  border: none;
  height: auto;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  width: 100%;
  background: transparent;
}
html .react-tel-input .form-control{
  border: none;
  height: auto;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  width: 100%;
  padding-left:36px;
  background: transparent;
}
html .react-tel-input .selected-flag{
  padding: 0;
}
html .react-tel-input .selected-flag:hover, html .react-tel-input .selected-flag:focus,
html .react-tel-input .flag-dropdown.open .selected-flag,
html .react-tel-input .flag-dropdown.open{
  background: transparent;
}
html .react-tel-input .flag-dropdown{
  background: transparent;
  border: none;
}
html .react-tel-input .selected-flag .arrow{
  width: 5px;
  height: 5px;
  border: solid #0F1324;
  border-width: 1px 0 0 1px;
  transform: rotate(-135deg);
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  top: auto;
  bottom: calc(50% + 4px);
  right: -24px;
    left: auto;
  transition: transform .1s linear;
}
html .react-tel-input .selected-flag .arrow.up{
  transform: rotate(45deg);
  margin: 0;
  border: solid #0F1324;
  border-width: 1px 0 0 1px;
  transition: transform .1s linear;
}

.react-tel-input .country-list {
  text-align: left;
}