
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    transition: all 250ms linear;
}

.main-page__container .slick-slider {
    max-height: 730px;
    width: 1560px;
}

.main-page__container .slick-slide:active, .slick-slide:focus {
    outline: none;
}

@media (max-width: 1660px) {
    .main-page__container .slick-slider {
        max-width: 1040px;
        margin: 0 auto;
    }

    .main-page__container .slick-slide {
        width: 520px !important;
    }
}

@media (max-width: 1140px) {
    .main-page__container .slick-slider {
        max-width: 520px;
        margin: 0 auto;
    }
}

@media (max-width: 700px) {
    .main-page__container .slick-slider {
        height: 750px;
    }
}

@media (max-width: 600px) {
    .main-page__container .slick-slider {
        max-height: 600px;
        max-width: 360px;
    }

    .main-page__container .slick-slide {
        width: 360px !important;
    }
}

@media (max-width: 550px) {
    /*.slick-slider .slick-list {*/
    /*	width: 350px;*/
    /*	margin: 0 auto;*/
    /*}*/
    /*.slick-slide {*/
    /*	width: 350px;*/
    /*}*/
    /*.slick-slide.slick-active.slick-current {*/
    /*	width: 351px !important;*/
    /*}*/
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}


.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;

    content: "";
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    /* height: 100%; */
    min-height: 1px;
}

[dir="rtl"] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-dots,
.custom_dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.office-wallets .slick-dots {
    margin-bottom: -16px;
    margin-top: 0;
}

.slick-dots li button,
.custom_dots li button {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    margin: 0 4px;
    transition: 0.5s;
    padding: 0;
    font-size: 0;
    color: transparent;
    vertical-align: top;
}

.slick-dots li button {
    background: #0f1324;
    opacity: 0.2;
}

.custom_dots li button {
    background: rgba(35, 31, 32, 0.50);
}

.slick-dots li,
.custom_dots li {
    list-style: none;
}

.custom_dots li {
    display: flex;
}

.slick-dots li.slick-active button {
    width: 16px;
}

.custom_dots li.slick-active button {
    width: 48px;
}

.news .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    height: auto;
}

.news .slick-initialized .slick-slide > div {
    display: flex;
}

.news .slick-track {
    display: flex;
}

.slick-arrow {
    display: flex !important;
}

.wallets-slider .slick-track {
    margin-left: 0;
    display: flex;
    min-width: 100%;
}

.wallets-slider .slick-slide {
    height: auto;
    display: flex;
}

.wallets-slider .slick-slide > div {
    display: flex;
    width: 100%;
}

.accounts-slider {
    width: 400px;
}

.accounts-slider .slick-list {
    margin-bottom: 16px;
    padding-top: 6px;
}

.payments-slides {
    margin-top: -16px;
}

.payments-slides .slick-track {
    padding-top: 16px;
}

.payments-slides .slick-slide {
    padding-left: 5px;
    padding-right: 5px;
}

.payments-slides .slick-slide:first-child {
    padding-left: 0;
}

.payments-slides .slick-slide:last-child {
    padding-right: 0;
}

.payments-slides .slick-dots {
    padding-top: 10px;
}

.createdpages-slider .slick-slide img {
    margin: 0 auto;
}

.createdpages-slider .slick-initialized .slick-slide {
    padding: 10px;
}

html .treegraph-slick-slide {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.store-slider .slick-slide:not(.slick-active) {
    padding-right: 5px;
}


.store-slider .slick-slide.slick-current {
    margin-right: 5px;
}

.store-slider .slick-slide div {
    outline: none;
}

.custom_dots {
    margin-top: 24px;
}

.custom_dots {
    height: 24px;
    margin: 24px auto 0;
    width: 120px;
    padding: 9px 12px;
    border-radius: 12px;
    background: rgba(35, 31, 32, 0.08);
}

@media (min-width: 480px) {
    .custom_dots {
        width: 86px;
    }
}

.custom_dots_anons {
    height: 24px;
    margin: 24px auto 0;
    width: fit-content;
    padding: 9px 12px;
    border-radius: 12px;
    background: rgba(35, 31, 32, 0.08);
}

.packages_slider .slick-track {
    display: flex;
}

.packages_slider .slick-slide {
    display: flex;
    flex-grow: 1;
}

.packages_slider .slick-slide > div {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
