.news-text {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    color: #0F1324;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 32px 0;
  }

  p {
    margin: 24px 0;
    word-break: break-all;
  }

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 24px;
    color: #0F1324;
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 36px;
    color: #0F1324;
  }

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #0F1324;
  }

  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #0F1324;
  }

  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #0F1324;
  }

  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #0F1324;
  }

  blockquote {
    border: 2px solid rgba(143, 143, 171, 0.15);
    border-left: none;
    border-right: none;
    padding: 32px 80px;
    font-style: italic;
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    color: #0F1324;
    margin: 24px 0;
    position: relative;

    &:before {
      content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAoCAYAAAC4h3lxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANfSURBVHgB1VlBUhpBFP2/hyGWyYIbBG+AS0urHCpalZ16AvAEcgPlBniCjCeI7rIxQCUmS7lB5gguE2H65/+eQUEYwJluKnkbhmF4b17/7t+/uwH+cyDkRNDsVgBKNU/jMaB6D0A1vl1Nf45ur3a3wAKW6ZTglQia3wKPVAOIjtl/JWkCevEURVAQq+qsHIGU8FwuJwh6iNQnoEEM/qAX7kRQEK/VWWpAQqjozTkCtQwV0INCuhzBu04v3H4AS8irs9DAx2a3OqRyF9I+h6jbtl+8qE6mgcNmt6bJ70r/45430EinvXBvAJZRVEctIwWkK42bddcvn1dnJgJJOP37lPTyNtxrgQPY0pkyIAPJozKTmr54zbn8BBzAps5UF5IsIKTcF6MYN0/BEWzqPEUgzQS/5DpGtWUjp8+DbZ2nCHB//CSfhNB29fIudIyBw+Z3ri8wkJBqUCE4ggsdY0BrMBmA+1PPZeu70DEGCPFIPrlPtsEhXOioAy6euEXMLOiy9V3plDisATIzEvUnfzC5WpcbHO/jpAbnCceAuD7BAVdb1756vPkS1qNVhPLrqNBXv/tZOiVE3JcL9LA7JlR64wwpbjFpSjY53xmBgG8FnA47Hxp3oY+P7WVG8uto0YGDxo/OCP9w5qpPFXh40Li757DWYqTtDRg+TFaFr0DEJuqLTLjSUTol8WEIOUkFMjndyySV9YBFne6kjpKBZQSo/Dkn6RgVIU/WsLOwqFOd1JmshapQHNVSUucsfAYs6ChdNnOKAsvgpWArKwo2wRntzCQCcIBx6zhGRcV+04kBbp19WANQ4ZETA4warAdVVwYq6xgH4NAAvJwxXUEMRGAf814+AvuIxID17ZIMThc6kSI9XR3aAHPezLlnXQeUCpX2hiHND3luaM+7nr1nXycm6CsZbByGS7CHcN6CxbbOeFPAZKGRGnXIwiBL9nmyl4upTuGxIDpfw90LuTYGpHU0qnoRE7IdLhyLloupzkkxHd7RYJ3xd/VMvhPlNZGQeturrHUL6gxeNtLM5q7MoCUotSg5JVkO3piN4e1FnjMDXuhfaD5GwiUl9qLDjszzgaD5k6fpOECChiwFMV1sCxn/aSBHPjYOO5518Ii5q6wzrqMikPGC0Ith88r2oco/g7/fpzBs/4NCdgAAAABJRU5ErkJggg==');
      position: absolute;
      width: 48px;
      height: 38px;
      left: 0;
    }
  }
}

.createNewsInfoImageTooltip {
  min-width: 509px;
  padding: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  @media (max-width: 992px) {
    min-width: unset;
  }
}
