@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

html,
body,
#__next {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    margin: 0px;
    padding: 0px;
    min-height: 100%;
    height: 100%;
}

.disableEvents {
    pointer-events: none;
}

.main-page__container {
    font-family: "Forum", serif;
    padding: 0 30px;
    max-width: 1600px;
    margin: 0 auto;
    color: #fff;
}

.main-button {
    padding: 28px 86px;
    font-weight: 600 !important;
    border-radius: 1111px;
    background: #dfa440;
    font-family: "Montserrat", sans-serif !important;
    cursor: pointer;
    font-size: 20px;
    display: inline-block;
    color: #ffffff !important;
    transition: all 0.2s;
}

.main-button:hover {
    background: #d69a36;
    transition: all 0.2s;
}

.main-button:focus {
    background: #eeac3c;
    transition: all 0.2s;
}

@media (max-width: 600px) {
    .main-button {
        padding: 18px 86px;
    }

}

@media (max-width: 460px) {
    .main-page__container {
        padding: 0 15px;
    }
}

@media (max-width: 420px) {
    .main-button {
        width: 100%;
        padding: 18px 86px;
        display: flex;
        justify-content: center;
    }
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
    width: 75px;
    height: 75px;
    opacity: 1;

    & > svg {
        width: 25px;
        height: 25px;
        left: 12px;
    }
}

.main-page {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    color: #231f20;
}

.main-page a {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    color: #231f20;
}

#__next {
    flex-shrink: 0;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 1;
    display: flex;
}

html {
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
    height: 100%;
}

body.is_loading {
    opacity: 0;
    pointer-events: none;
}

body {
    display: flex;
    overflow-y: auto;
    overscroll-behavior-y: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: scrollbar;
}

body {
    padding: 0;
    margin: 0;
    border: 0;
    width: 100%;
    overflow-x: hidden;
    background-color: #fff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    color: #0f1324;
    -webkit-font-smoothing: antialiased;
    text-shadow: 0 0 1px rgba(51, 51, 51, 0.2);
}

/* for Firefox */
html,
body {
    scrollbar-color: rgba(199, 204, 218, 1) rgba(199, 204, 218, 0.2);
    scrollbar-width: thin;
}

html {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    /*height: 100%;*/
    font-size: 16px;
    font-family: "Roboto", sans-serif;
}

html.auth-page {
    display: flex;
    flex-direction: column;
    height: unset;
}

html.course-page {
    height: unset;
}

html.auth-page body {
    flex-grow: 1;
}

html.uscrollable body {
    overflow-y: hidden;
}
html[data-uscrollable] body {
    overflow-y: hidden;
}

.checkBoxVerification a:hover {
    text-decoration: underline !important;
}

@media screen and (min-width: 768px) {
    body {
        height: 100%;
        flex-direction: column;
    }
}

@media screen and (max-width: 767px) {
    html.uscrollable {
        overflow-y: hidden;
    }
    html[data-uscrollable] {
        overflow-y: hidden;
    }

    html {
        overflow-x: hidden;
        /*height: 100vh;*/
        overflow-y: auto;
    }

    html {
        font-size: 14px;
    }

    html.auth-page {
        height: 100%;
    }
}

a:not([class]) {
    color: rgba(158, 138, 106, 1);
}

a:not([class]):hover,
a:not([class*=""]):focus,
a:not([class*=""]):active {
    color: rgba(158, 138, 106, 1);
}

a:not([class]):visited {
    color: rgba(158, 138, 106, 1);
}

header nav a,
header nav a:not([class]):visited,
header nav a:not([class]):hover,
.singleMenu a {
    color: currentColor !important;
}

*:not(#navigation) ~ a:not([class*=""]):focus,
*:not(#navigation) ~ a:not([class*=""]):active {
    color: currentColor !important;
}

a:not([class]):hover,
a:not([class*=""]):focus,
a:not([class*=""]):active,
#navigation a:not([class]):hover,
#navigation a:not([class*=""]):focus,
#navigation a:not([class*=""]):active {
    color: rgba(158, 138, 106, 1) !important;
}

@font-face {
    font-family: "Kudryashev";
    src: url("../../fonts/KudryashevDisplay.woff2") format("woff2");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("../../fonts/Inter-Light.woff2") format("woff2"),
    url("../../fonts/Inter-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("../../fonts/Inter-Regular.woff2") format("woff2"),
    url("../../fonts/Inter-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("../../fonts/Inter-Bold.woff2") format("woff2"),
    url("../../fonts/Inter-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

/*@font-face {*/
/*font-family: "Roboto";*/
/*src: url("../../fonts/Roboto-Regular.woff2") format("woff2"),*/
/*url("../../fonts/Roboto-Regular.ttf") format("truetype");*/
/*font-weight: 400;*/
/*font-display: swap;*/
/*font-style: normal;*/
/*}*/
/*@font-face {*/
/*font-family: "Roboto";*/
/*src: url("../../fonts/Roboto-Medium.woff2") format("woff2"),*/
/*url("../../fonts/Roboto-Medium.ttf") format("truetype");*/
/*font-weight: 500;*/
/*font-display: swap;*/
/*font-style: normal;*/
/*}*/

/*@font-face {*/
/*font-family: "Roboto";*/
/*src: url("../../fonts/Roboto-Bold.woff2") format("woff2"),*/
/*url("../../fonts/Roboto-Bold.ttf") format("truetype");*/
/*font-weight: 700;*/
/*font-display: swap;*/
/*font-style: normal;*/
/*}*/

*,
:before,
:after {
    margin: 0;
    padding: 0;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

/* ::-webkit-resizer {
    display: none;
} */
:not(table) {
    border: 0;
}

.packageDescriptionWrapper table tbody td {
    border: 1px solid black;
}

.packageDescriptionWrapper > *:not(p) {
    margin: revert;
    padding: revert;
}

.packageDescriptionWrapper > blockquote {
    font-style: italic;
    border-left: 5px solid #ccc;
    padding-left: 20px;
    font-family: Georgia, Times, "Times New Roman", serif;
}

.requisitesBlock * {
    margin: revert;
    padding: revert;
}

.requisitesBlock > blockquote {
    font-style: italic;
    border-left: 5px solid #ccc;
    padding-left: 20px;
    font-family: Georgia, Times, "Times New Roman", serif;
}

.requisitesBlock tbody td {
    border: 1px solid;
}

.requisitesBlock blockquote {
    border-left: 5px solid #ccc;
    padding-left: 20px;
    font-style: italic;
}

.ckeditor_inner_preview table,
.ckeditor_inner_preview td {
    border: 1px solid black;
}

*::placeholder,
*::-webkit-placeholder {
    color: #ceceda;
}

::-webkit-scrollbar {
    width: 8px;
    height: 5px;
    background-color: rgba(199, 204, 218, 0.2);
}

::-webkit-scrollbar-thumb {
    background-color: rgba(199, 204, 218, 1);
    border-radius: 3px;
}

.invisible-scrollbar::-webkit-scrollbar {
    display: none;
}

a {
    cursor: pointer;
    text-decoration: none;
}

.fakeLink {
    color: inherit;
}

a:hover {
    text-decoration: none !important;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
}

@media screen and (max-width: 1366px) {
    tspan {
        font-size: 10px;
    }
}

select::-ms-expand {
    display: none;
}

input,
button,
textarea,
select {
    outline: none;
    font-family: "Roboto", sans-serif;
    background-color: initial;
}

textarea {
    resize: vertical;
}

::selection {
    background-color: #82828230;
}

::-moz-selection {
    background-color: #82828230;
}

input:disabled,
textarea:disabled {
    opacity: 1;
    background-color: transparent;
}

.react-datepicker__input-container input:disabled {
    opacity: 0.5;
}

.user-select-disabled::selection {
    background-color: transparent;
}

.user-select-disabled::-moz-selection {
    background-color: transparent;
}

#officeportalDropdown {
    position: relative;
}

.rbc-row.rbc-month-header {
    display: none;
}

.rbc-row,
.rbc-month-view {
    display: flex;
}

.rbc-date-cell.rbc-off-range {
    padding: 10px;
}

.rbc-date-cell {
    padding: 10px;
}

.rbc-month-view {
    width: 100%;
    overflow-x: auto;
}

.checkbox {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    border: 1px solid rgb(149, 148, 149) !important;
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.checkbox:checked {
    position: relative;
    background-color: rgb(67, 95, 197);
}

.checkbox:checked::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 10px;
    left: calc(50% - 3px);
    top: calc(50% - 6px);
    border-color: #fff;
    border-style: solid;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checkboxmanual {
    width: 20px;
    height: 20px;
    /* margin-right: 12px; */
    border: 1px solid rgb(149, 148, 149) !important;
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.deletedmanual {
    position: absolute;
    width: 10px;
    height: 1px;
    border-color: #fff;
    border-style: solid;
    border-width: 0 0 2px 0;
}

.checkedmanual {
    content: "";
    position: absolute;
    width: 6px;
    height: 10px;
    top: calc(50% - 6px);
    border-color: #fff;
    border-style: solid;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.paginationArrow:hover svg {
    fill: #fff !important;
    stroke: none;
}

@-moz-document url-prefix() {
    input[type="password"] {
        font-size: 10px;
        letter-spacing: 3px;
        font-family: serif;
    }
}

@media (max-width: 767px) {
    body {
        font-size: 14px;
    }
}

.CookieModalText a {
    color: rgb(189, 165, 127);
}

input:-webkit-autofill + .hideOnAutofill,
input:-webkit-autofill:hover + .hideOnAutofill,
input:-webkit-autofill:focus + .hideOnAutofill,
textarea:-webkit-autofill + .hideOnAutofill,
textarea:-webkit-autofill:hover + .hideOnAutofill,
textarea:-webkit-autofill:focus + .hideOnAutofill,
select:-webkit-autofill + .hideOnAutofill,
select:-webkit-autofill:hover + .hideOnAutofill,
select:-webkit-autofill:focus + .hideOnAutofill {
    display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]:hover,
input[type="number"]:focus {
    -moz-appearance: number-input;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[readonly] {
    background: transparent;
}

.icons_modal_normal svg {
    max-width: 30px;
    max-height: 30px;
}

.icons_modal_large svg {
    max-width: 130px;
    max-height: 130px;
}

html .react-datepicker-wrapper .react-datepicker__input-container .datePickerPagemanagement {
    opacity: 1;
    color: #000;
    margin-left: 5px;
}

@keyframes pulse {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.main-btn-brush {
    opacity: 0;
    animation: pulse 1s infinite;
    animation-direction: alternate;
    transition: 0.3s;
    will-change: opacity;
}

.main-btn-brush1 {
    animation-delay: 1s;
}

.main-btn-brush2 {
    animation-delay: 1.5s;
}

.main-btn-brush3 {
    animation-delay: 2s;
}

.bestseller-item:after {
    content: "";
    position: absolute;
    width: 330px;
    height: 330px;
    background: rgba(44, 65, 53, 0.5);
    -webkit-filter: blur(30px);
    filter: blur(30px);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 50%;
    transition: 0.3s;
}

.bestseller-item:before {
    content: url("../../../public/src/img/main/bestseller/circles.png");
    position: absolute;
    width: 550px;
    height: 550px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: 0;
    transition: 0.3s;
}

.bestseller-item:active:before,
.bestseller-item:focus:before {
    opacity: 1;
}

@media (max-width: 1500px) {
    .bestseller-item:after {
        width: 320px;
        height: 320px;
        filter: blur(20px);
    }
}

@media (max-width: 1200px) {
    .bestseller-item:after {
        width: 260px;
        height: 260px;
    }
}

.bestseller-item:hover:after {
    background: #2c4135;
}

.bestseller-item:hover .bestseller-item-icon {
    background: #2c4135;
}

.main-cat-item:hover .cat-item-icon {
    background: #2c4135;
}

.main-cat-item .main-cat-item-block {
    position: relative;
    transition: 0.3s;
}

.main-cat-item .main-cat-item-block:after {
    content: "";
    position: absolute;
    width: 252px;
    height: 159px;
    background: #bca57f;
    opacity: 0.5;
    filter: blur(15px);
    border-radius: 113px;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.main-cat-item:hover .main-cat-item-block {
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
}

.main-cat-item .main-cat-item-block-center:before {
    content: url("../../../public/src/img/main/category/flora-xs.png");
    position: absolute;
    top: -63px;
    left: -35px;
    opacity: 0;
    transition: 0.3s;
    z-index: -1;
}

.main-cat-item:hover .main-cat-item-block-center:before {
    opacity: 1;
}

@media (max-width: 1109px) {
    .main-cat-item:hover .main-cat-item-block-center:before {
        opacity: 0;
    }
}

@media (max-width: 767px) {
    .main-cat-item .main-cat-item-block-center:before {
        opacity: 1;
    }
}

.cert-item-slide {
    position: relative;
}

.cert-item-slide:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(188, 165, 127, 0.7), rgba(188, 165, 127, 0.7));
    border-radius: 10px;
    opacity: 0;
    transition: 0.3s;
}

.cert-item-slide:hover:after {
    opacity: 1;
}

.cert-item-slide:before {
    content: url("../../../public/src/img/main/cert/icon.svg");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 0.3s;
    z-index: 1;
}

.cert-item-slide:hover:before {
    opacity: 1;
}

.cert-item-slide-icon {
    position: absolute;
    bottom: 24px;
    right: 16px;
    opacity: 1;
    transition: 0.3s;
}

.cert-item-slide:hover .cert-item-slide-icon {
    opacity: 0;
}

/* FAQ */
.inputSearchFAQ label {
    padding: 10px 16px;
    line-height: 1;
    background: transparent;
}

/* IOS zooming */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select:focus,
    textarea,
    input:focus,
    textarea:focus,
    select {
        font-size: 16px;
    }
}

@supports (-webkit-overflow-scrolling: touch) {
    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select:focus,
    textarea,
    input:focus,
    textarea:focus,
    select {
        font-size: 16px;
    }
}

@media only screen and (hover: none) and (pointer: coarse) {
    input,
    select,
    textarea {
        font-size: 16px;
    }

    input:focus,
    select:focus,
    textarea:focus {
        font-size: 16px;
    }
}

input,
input:active,
input:focus,
input:focus-within,
input:hover,
input:visited {
    font-size: 16px !important;
}

.arrowTopLink {
    display: flex;
    align-items: center;
    justify-content: center;
    /*position: absolute;*/
    width: 50px;
    height: 50px;
    right: 0;
    /*top: 46px;*/
    border-radius: 50%;
    background: #bca57f;
    transition: 0.3s;
    position: absolute;
    z-index: 2;
    bottom: 16px;
    left: calc((100% - 1504px) / 2 + 1504px - 16px);
    transform: translateX(-100%);
    opacity: 1;
    scale: 1;
}

.arrowTopLinkHide {
    opacity: 0;
    scale: 0;
    transition: 0.3s;
}

@media (max-width: 1725px) {
    .arrowTopLink {
        left: calc((100% - 90%) / 2 + 90% - 16px);
    }
}

@media (max-width: 1525px) {
    .arrowTopLink {
        left: calc((100% - 90%) / 2 + 90% - 16px);
    }
}

@media (max-width: 767px) {
    .arrowTopLink {
        left: calc(100% - 16px);
    }
}

a[href*="tel"]:hover > span {
    color: currentColor;
}

.spin-container {
    position: absolute;
    font-size: 6.6px;
    transform: translate(240px, 108px);
}

@media (max-width: 660px) {
    .spin-container {
        position: absolute;
        font-size: 6.6px;
        transform: translate(140px, 80px);
    }
}

.spin-container.pause-animation {
    display: none;
}

.spin-container.pause-animation .spinner:before,
.spin-container.pause-animation .spinner:after {
    animation-play-state: paused;
}

.spinner,
.spinner:before,
.spinner:after {
    border-radius: 50%;
}

.spinner {
    z-index: 0;
    color: #fff;
    text-indent: -99999em;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 10em;
    height: 10em;
    box-shadow: inset 0 0 0 1em;
    transform: translateZ(0);
}

.spinner:before,
.spinner:after {
    position: absolute;
    content: "";
}

.spinnerCartWrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0,0,0 ,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}
.spinnerCart {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 100px;
    height: 100px;

    & .path {
        stroke: hsl(210, 70, 75);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}


.spinner:before {
    width: 5em;
    height: 10em;
    /* color to change */
    background: #fff;
    border-radius: 0 10em 10em 0;
    top: 0;
    left: 5em;
    transform-origin: 0 5em;
    animation: spin 2s infinite ease 1.5s;
}

.spinner:after {
    width: 5em;
    height: 10em;
    /* color to change */
    background: rgba(189, 165, 127, 1);
    top: 0;
    left: 0;
    border-radius: 10em 0 0 10em;
    transform-origin: 5em 5em;
    animation: spin 2s infinite ease;
}

.spinnerInner {
    position: absolute;
    z-index: 100;
    border-radius: 10em;
    /* color to change */
    background-color: #fff;
    top: 1em;
    left: 1em;
    width: 8em;
    height: 8em;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


iframe button .ytp-large-play-button.ytp-button.ytp-large-play-button-red-bg {
    display: none !important;
}

.arrowTopLink:hover {
    background: #2c4135;
}

@media (max-width: 767px) {
    .arrowTopLink {
        display: flex;
        position: fixed;
    }
}

/*.main-page > .LazyLoad {*/
/*min-height: 100vw;*/
/*}*/
/*.main-page > .LazyLoad.is-visible{*/
/*min-height: 1px;*/
/*}*/
.main-page .slick-slide img {
    height: auto !important;
    min-height: 1px !important;
}

a[href="https://flawlessmlm.com/"]:hover {
    opacity: 0.65;
}

.sticky-table .sticky-table-table .sticky-table-row:first-child {
    will-change: transform;
    /*transition: transform 0.2s;*/
}

.Firefox .sticky-table .sticky-table-table .sticky-table-row:first-child {
    will-change: transform;
    transition: transform 0.2s;
}

.officePage #input_name,
.officePage #input_second_name {
    text-transform: capitalize;
}

.bg-blur {
    backdrop-filter: blur(10px);
}

.info_icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
ol {
    padding-left: 48px!important;
}

.blurAnnonsText::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px; /* Визначте висоту блюру відповідно до вашого тексту */
    background: linear-gradient(transparent, white); /* Градієнт від прозорого до білого */
    filter: blur(0px); /* Задаємо блюр */
    pointer-events: none; /* Заборона спрацьовувати події на блюрованій області */
    z-index: 0;
}