
/*Loader*/

.loader-wrapper{
  transform: scale(.6);
  .loader-index{
    &:after {
      content: "";
      width: 4em;
      height: 4em;
      background: #D3D7EF;
      border-radius: 50%;
      position: absolute;
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
      -webkit-animation: grow 2s cubic-bezier(0.14, 0.05, 0.55, 0.5) infinite
      alternate;
      animation: grow 2s cubic-bezier(0.14, 0.05, 0.55, 0.5) infinite alternate;
    }
    span {
      width: 2em;
      height: 2em;
      background: #E5E7F5;
      border-radius: 50%;
      margin-right: 2em;
      position: relative;
      -webkit-transform: translateX(7em);
      transform: translateX(7em);
      -webkit-animation: move 4s ease-in-out infinite;
      animation: move 4s ease-in-out infinite;
      &:before {
        content: "";
        width: 2em;
        height: 2em;
        background: #E5E7F5;
        border-radius: 50%;
        position: absolute;
        left: 3em;
        -webkit-transform: translateX(0em);
        transform: translateX(0em);
        -webkit-animation: shrink 2s ease-in-out infinite;
        animation: shrink 2s ease-in-out infinite;
      }
      &:after {
        content: "";
        width: 2em;
        height: 2em;
        background: #E5E7F5;
        border-radius: 50%;
        position: absolute;
        right: 3em;
        -webkit-transform: translateX(0em);
        transform: translateX(0em);
        -webkit-animation: shrink 2s ease-in-out infinite;
        animation: shrink 2s ease-in-out infinite;
      }
    }
  }
}

.shine{
  width: 100px;
  right: -100px;
  animation: shine 1.6s infinite linear;
  top: 0;
  bottom: 0;
  position: absolute;
}

@-webkit-keyframes grow {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    background: #D3D7EF;
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background: #D3D7EF;
  }
}
@keyframes grow {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    background: #D3D7EF;
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background: #D3D7EF;
  }
}
@-webkit-keyframes move {
  0% {
    -webkit-transform: translateX(7em);
    transform: translateX(7em);
  }
  50% {
    -webkit-transform: translateX(-5em);
    transform: translateX(-5em);
  }
}
@keyframes move {
  0% {
    -webkit-transform: translateX(7em);
    transform: translateX(7em);
  }
  50% {
    -webkit-transform: translateX(-5em);
    transform: translateX(-5em);
  }
}
@-webkit-keyframes shrink {
  0% {
    -webkit-transform: translateX(0em);
    transform: translateX(0em);
  }
  50% {
    -webkit-transform: translateX(-1em);
    transform: translateX(-1em);
  }
}
@keyframes shrink {
  0% {
    -webkit-transform: translateX(0em);
    transform: translateX(0em);
  }
  50% {
    -webkit-transform: translateX(-1em);
    transform: translateX(-1em);
  }
}
@-webkit-keyframes shine {
  0% {
    right: 100%
  }
  50% {
    right: -100px
  }
}
@keyframes shine {
  0% {
    right: 100%
  }
  50% {
    right: -100px
  }
}
.loader-wrapper svg {
  width: 0;
  height: 0;
}
