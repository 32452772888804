.sticky-table{
  overflow: unset !important;
  &-table{
    width: 100%;
  }
  &-row{
    p::selection{
      background-color: #82828230;
    }
    p::-moz-selection{
      background-color: #82828230;
    }
    label::selection{
      background-color: #82828230;
    }
    label::-moz-selection{
      background-color: #82828230;
    }

    &:first-child{
      p::selection{
        background-color: transparent;
      }
      p::-moz-selection{
        background-color: transparent;
      }
      label::selection{
        background-color: transparent;
      }
      label::-moz-selection{
        background-color: transparent;
      }
      .sticky-table-cell{
        font-weight: 700;
      }
    }
  }
  .sticky-table-cell {
    position: relative;
    vertical-align: middle;
  }
}
.sticky-table-no-column-count{
  .sticky-table{
    &-row{
      &:first-child{
        .sticky-table-cell{
          z-index: 11 !important;
          border-bottom: none !important;
        }
      }
    }
  }
}
.sticky-table-column-count{
  .sticky-table{
    &-row{
      &:first-child{
        .sticky-table-cell:not(:last-child){
          border-right: 1px solid #e1e3e5;
        }
      }
      &:not(:first-child){
        .sticky-table-cell:not(:first-child){
          z-index: 0 !important;
        }
      }
    }
  }
}
.sticky-table-fullscreen{
  .custom-scroll{
    border-top-right-radius: 0 !important;
  }
  .sticky-table{
    &-row{
      &:first-child{
        .sticky-table-cell{
          &:last-child{
            border-top-right-radius: 0;
          }
        }
      }
    }
    .sticky-table-cell {
      &:last-child{
        border-top-right-radius: 0;
      }
    }
  }
}
