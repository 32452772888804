.react-datepicker-range{
  .react-datepicker {
    display: flex;
    box-shadow: none;
    .react-datepicker__navigation--previous{
      right: auto;
      margin-left: 16px;
    }
    .react-datepicker__month-container{
      .react-datepicker__header{
        display: block;
        .react-datepicker__current-month{
          text-align: -webkit-center;
          text-transform: capitalize;
        }
        .react-datepicker__header__dropdown{
          padding-top: 0px;
        }
      }
      .react-datepicker__day-name{
        text-transform: capitalize;
      }
    }
  }
}