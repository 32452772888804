.flexbox-fix {
    display: none !important;    
}
.sketch-picker {
    padding: 0 !important;
    border-radius: 14px;
    overflow: hidden;
    height: 140px;
}
.hue-picker {
    height: 10px !important;
}
.hue-horizontal {
    border-radius: 5px !important;

}