.mapboxgl-popup-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 382px;
    padding: 24px;
    background:#fff;
    border-radius: 14px;
    box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.1);
}
.mapboxgl-popup-close-button{
    display: none;
}
.mapboxgl-popup-close-button:hover{
    opacity: .6;
    background: transparent;
}

