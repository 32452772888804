
.contentPage{
  background-color: #e5e5e5;
}

.ckeditor_inner_preview {
  overflow: hidden;
  ul,
  ol{
    margin: 1em 0;
    padding: 0 40px;
  }
  blockquote{
    padding: 10px;
    border-left: 4px solid #cbcbcb;
    margin-bottom: 10px;
    font-style: italic;
  }
}

.ckeditor_inner {
  overflow: hidden;
  ul,
  ol{
    margin: 1em 0;
    padding: 0 40px;
  }
  blockquote{
    padding: 10px;
    border-left: 4px solid #cbcbcb;
    margin-bottom: 10px;
    font-style: italic;
  }
}

.imgHover{
  div{
    transition: all ease .25s;
  }
  &:hover{
    opacity:0.8;
    div{
      display: block;
    }

  }
}

.oneImg div{
  transition: all ease .25s;
  &:hover{
    opacity:0.8;
    div{
      display: block;
    }
  }
}



.preImgHover {
  div{
    transition: all ease .25s;
  }
  &:hover{
    opacity:0.8;
    div{
      display: block;
    }
  }
}

.tr-component:hover .rang {
  background-color: rgb(238,240,250)
}
.dragging {
  cursor: grab !important;
}

.counter-title-custom {
  color: #0F1324;
  margin-right: 3px;

  &:after {
    content: ":";
    position: absolute;
    padding-left: 2px;

    @media (max-width: 991.98px) {
      content: ""
    }
  }
}

html .profileMain {
  position: static;
}