.product-hover::before{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  content: "";
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  transition: all .4s;
  border: 1px solid #E2E2E4;
  box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.22);
}
.product-hover:hover{
  z-index: 10;

}
.product-hover:hover:before{
  transform: scale(1.05);
  transition: all .4s;
}