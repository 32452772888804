path.rd3t-link {
    stroke: #C7CCDA;
}

/* .tree-item .no-rang {

} */

.rd3t-node .node-item,
.rd3t-leaf-node .node-item {
    display: flex;
    border-radius: 16px;
    padding: 14px;
    margin: 0 auto;
}

.rd3t-node .node-item.node-item-volumes,
.rd3t-leaf-node .node-item.node-item-volumes {
    /* margin: 0 20px; */
    min-height: 164px;
}

.rd3t-node .no-node,
.rd3t-leaf-node .no-node {
    padding: 14px;
    margin: 0 auto;
    /* margin-top: 30px; */
    background: #F0F1F5;
    border-radius: 16px;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #6F717C;
}

.node-item-browser {
    align-items: center;
    margin-top: 0;
}

.treeItem .tree-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.tree-more-down-safari{
    width: 100%;
}
.treeItem .tree-more-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -50px;
}
.rd3t-node .tree-more.tree-more-browser {
    /*position: absolute;*/
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
    /*margin-top: 12px;*/
}

.tree-more-browser {
    bottom: 24px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.rd3t-node .tree-more span {
    /* position: absolute;
    left: 50%;
    top: -16px; */
    width: 2px;
    height: 16px;
    background: #C7CCDA;
}

.treeItem .tree-more > div {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 32px;
    background: #2c4135;
    border-radius: 8px;
    color: #fff;
}
.treeItem .tree-more > div.button-up {
    width: 32px;
}

.treeItem .tree-more-browser > div:first-child{
    margin-top: 10px;
}

.treeItem .tree-volume-browser + .tree-more-browser > div {
    margin-top: 20px;
}

/* .treeItem .tree-more-level {

} */

.tree-more-level-browser.tree-more.tree-more-level {
    margin-right: -30px;
    margin-left: auto;
}

.rd3t-leaf-node .tree-volume,
.treeItem .tree-volume {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    border-radius: 8px;
    margin: -10px auto 0 auto;
    overflow: hidden;
}

.rd3t-leaf-node .tree-volume.tree-volume-browser,
.treeItem .tree-volume.tree-volume-browser {
    margin: -12px auto;
     transform: translateY(0);
}

.rd3t-leaf-node .tree-volume > div,
.treeItem .tree-volume > div {
    padding: 4px 12px;
    display: flex;
    white-space: nowrap;
}

.treeItem {
    border-radius: 16px;
}

.treeItem .treeItem > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.rd3t-leaf-node.node__leaf .treeItem .node-item,
.node__branch .treeItem .node-item {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: 0.1s all linear;
    min-height: 180px;
}

.rd3t-leaf-node.node__leaf .treeItem .node-item:not(.deleted-item):hover,
.node__branch .treeItem .node-item:not(.deleted-item):hover {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
}

.rd3t-leaf-node.node__leaf .treeItem .node-item.deleted-item,
.node__branch .treeItem .node-item.deleted-item,
.rd3t-leaf-node.node__leaf .treeItem .node-item.no-node,
.node__branch .treeItem .node-item.no-node {
    cursor: grab;
}

.node__root .treeItem .node-item {
    border: 2px solid #2c4135;
}

.tree-item-wrapper {
    display: flex;
    margin: 0 auto;
    padding-top: 70px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start !important;
}

.tree-item-wrapper-browser {
    padding-top: 0px !important;
    justify-content: center !important;

}