.scroll-container {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.scroll-container::-webkit-scrollbar {
  height: 3px;
  border-radius: 10px;
  background-color: #eff1f7;
}

.scroll-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #d9e1f0;
}

.scroll-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eff1f7;
}


/*custom scroll */
.custom-scroll{
  overflow: auto;
  scrollbar-width: thin;
}
.custom-scroll::-webkit-scrollbar{
  height: 8px;
  width: 8px;
  background-color: #F5F5F5;
}
.custom-scroll::-webkit-scrollbar-thumb{
  height: 8px;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #D9DFE4;
}

.scrollbar-hidden::-webkit-scrollbar-thumb,
.scrollbar-hidden::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.scrollbar-hidden { // Mozilla
  scrollbar-width: none;
}

.custom-scroll4::-webkit-scrollbar {
  height: 4px;
  background: #DFE2ED;
  border-radius: 2px;
}

.custom-scroll4::-webkit-scrollbar-thumb {
  height: 4px;
  background: #C7CCDA;
  border-radius: 2px;
}
