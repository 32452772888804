
/*react web tooltip*/
.react-tooltip{
  border-radius: 12px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 500;
  box-shadow: 0px 6px 10px rgba(14, 12, 32, 0.2);
  background: #0E0C20 !important;
  opacity: 1 !important;
  max-width: 300px;
  position: relative;
  z-index: 10003 !important;
  @media (max-width: 479px) {
    max-width: 240px;
  }
}
.react-tooltip.react-tooltip-sm{
  padding: 8px;
  max-width: 164px;
}
.react-tooltip.react-tooltip-lg{
  padding: 8px 10px;
  max-width: 376px;
}
.react-tooltip.customPosition{
  position: absolute !important;
  transform: translate(-50%, -95%);
  left: 14px !important;
  top: 0 !important;
}

.react-tooltip.react-tooltip-xl{
  @media (min-width: 992px) {
    min-width: 800px;
  }
  @media (max-width: 991px) {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.react-tooltip.staticPosition{
  padding: 8px 10px;
  max-width: 476px;
}
.zIndex10000{
  z-index: 10003 !important;
}